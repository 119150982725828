import type { SeonDeps } from '@patrianna-payments/analytics/seon'
import { Seon } from '@patrianna-payments/analytics/seon'

import { serviceDependencies } from 'services'

const seonDeps: SeonDeps = {
  api: serviceDependencies.fraud,
}

const {
  getSeonSessionId: getSeonSessionIdLib,
  seonLibraryInitialization,
  setSeonSessionIdToDB: setSeonSessionIdLib,
} = new Seon(seonDeps)

export const seonInit = async () => {
  try {
    await seonLibraryInitialization(
      { url: process.env.SEON_SCRIPT_URL, host: process.env.SEON_HOST },
      { depth: 1, retryCount: 8 }
    )
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('seonInit', err)
  }
}

export const getSeonSessionId = async () => {
  try {
    const data = await getSeonSessionIdLib()

    return Promise.resolve<string>(data)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('getSeonSessionId', err)

    return null
  }
}

export const setSeonSessionIdToDB = async () => {
  try {
    await setSeonSessionIdLib()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('setSeonSessionId', err)
  }
}
