import type {
  DailyBonusInfo,
  GetAccountBonusesResponse,
  InvitationBonusInfo,
} from '@patrianna/shared-patrianna-types/store/AccountBonusesModule'
import type { BaseBonus, InvitationBonus } from '@patrianna/shared-patrianna-types/store/BonusesFlowModule'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { BonusesFlowState, SocialMediaReward } from 'store/types/BonusesFlowModule'

import { getActiveBonuses } from './utils'

const initialState: BonusesFlowState = {
  socialMediaReward: null,
  dailyBonuses: null,
  manualBonuses: null,
  invitationBonuses: null,
  weeklyRewardBonuses: null,
  showCaptcha: false,
  loading: false,
  acceptingDailyBonus: false,
}

export const bonusesFlowSlice = createSlice({
  name: 'bonusesFlow',
  initialState,
  reducers: {
    // @TODO will remove?
    saveDailyBonuses: (state, action: PayloadAction<{ dailyBonuses: DailyBonusInfo[] }>) => {
      state.dailyBonuses = getActiveBonuses(action.payload.dailyBonuses)
    },

    saveManualBonuses: (state, action: PayloadAction<{ manualBonuses: BaseBonus[] }>) => {
      state.manualBonuses = action.payload.manualBonuses
    },

    saveInvitatonBonuses: (
      state,
      action: PayloadAction<{ invitationBonuses: (InvitationBonus | InvitationBonusInfo)[] }>
    ) => {
      state.invitationBonuses = action.payload.invitationBonuses
    },

    receivedBonuses: (state, action: PayloadAction<{ body: GetAccountBonusesResponse }>) => {
      // dispatch(saveDailyBonuses(body?.daily))
      // dispatch(saveManualBonuses(body?.manual))
      // dispatch(saveInvitatonBonuses(body?.invitation))
      state.manualBonuses = action.payload.body.manual
      if (process.env.DAILY_REWARDS_ENABLED) {
        state.dailyBonuses = action.payload.body.daily
      }
      state.invitationBonuses = action.payload.body.invitation
      state.showCaptcha = action.payload.body.showCaptcha
      //disabling rough ride modal
      // state.weeklyRewardBonuses = action.payload.body.cashback
    },
    setBonusesLoading: (state, action: PayloadAction<{ loading: boolean }>) => {
      state.loading = action.payload.loading
    },
    setAcceptingDailyBonus: (state, action: PayloadAction<{ accepting: boolean }>) => {
      state.acceptingDailyBonus = action.payload.accepting
    },
    setShowDailyBonusCaptcha: (state, action: PayloadAction<{ shouldShow: boolean }>) => {
      state.showCaptcha = action.payload.shouldShow
    },
    setSocialMediaReward: (state, action: PayloadAction<{ reward: SocialMediaReward }>) => {
      state.socialMediaReward = action.payload.reward
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof bonusesFlowSlice.actions>>
export const {
  saveManualBonuses,
  saveInvitatonBonuses,
  receivedBonuses,
  setBonusesLoading,
  setAcceptingDailyBonus,
  setShowDailyBonusCaptcha,
  setSocialMediaReward,
} = bonusesFlowSlice.actions
