import process from 'process'

import { setCookie, getCookie } from '@patrianna/shared-utils/cookie'

export const OPTIMIZELY_FIRST_PURCHASE_COOKIE = 'OPTIMIZELY_FIRST_PURCHASE_COOKIE'
export const OPTIMIZELY_PAGE_API_NAME = 'scratchful_manual'
export const AB_TEST_ENABLED = '0'
export const AB_TEST_DISABLED = '1'
export const AB_TEST_IMG_URL = `${process.env.IMG_ORIGIN}/001_PopUps_New/first_purchase_ab_test_square.png`
export const AB_TEST_PREVIOUS_IMG_URL = `${process.env.IMG_ORIGIN}/001_PopUps_New/first_purchase_ab_test.png`
export const CLICKED_EXTRA_SWEEP_CTA = 'clicked_extra_sweep_cta'
export const CLICKED_ON_CLOSE_EXTRA_SWEEP = 'clicked_on_close_extra_sweep'
const domain = process.env.DOMAIN

type OptimizelyPageApi = typeof OPTIMIZELY_PAGE_API_NAME
type OptimizelyCookie = typeof OPTIMIZELY_FIRST_PURCHASE_COOKIE

// For cases where full automatic settings is not working properly we should relaunch optimizely manually
// B2SPIN-4575 Example: user in the experiment but after making first purchase should be excluded from
// currently in hook useABTest we get the correct value but actually he is still in
export const manualOptimizelyLaunch = (isActive: boolean, pageName: OptimizelyPageApi) => {
  if (typeof window !== 'undefined') {
    window.optimizelyEdge?.push({
      type: 'page',
      pageName: pageName,
      isActive: isActive,
    })
  }
}

export function clearOptimizelyExperiment(pageName: OptimizelyPageApi, cookieName: OptimizelyCookie) {
  const cookie = getCookie(cookieName, document.cookie)
  const expires = new Date('Thu, 01 Jan 1970 00:00:00 UTC')

  if (cookie) {
    setCookie(cookieName, '', { expires, domain })
    manualOptimizelyLaunch(false, pageName)
  }
}

export const setOptimizelyCookie = (cookieName: OptimizelyCookie, value: string) => {
  const ONE_MONTHS_MS = 2592000000
  setCookie(cookieName, value, {
    domain,
    expires: new Date(Date.now() + ONE_MONTHS_MS),
  })
}
