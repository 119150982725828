import { getCookie } from '@patrianna/shared-utils/cookie'
import { isBrowser } from '@patrianna/shared-utils/helpers'

import { USER_COUNTRY } from 'app/constants/cookies'

import getCountryConfig from '.'

/**
 * This is a function takes user country from cookies and return config with options specific to the country.
 * Can be used only on client.
 *
 * @return {Object} CountryConfig
 *
 * @example
 *
 * const { profilePageFeature } = getCountryConfigClient()
 *
 * {profilePageFeature.enabled && <Component>}
 *
 */
export default function getCountryConfigClient() {
  const country = isBrowser() ? getCookie(USER_COUNTRY, document.cookie) : undefined
  const countryInfo = getCountryConfig(country)

  return countryInfo
}
